.visualize {
    width: 100%;

    
  border-spacing: 0 0.8rem;

    &_title_row,
    &_content_row {
        text-align: center;

        font-size: 1em;
    }

    &_title_row {
        font-size: 1em;
        font-weight: bold;
    }

    &_content_row {
        font-weight: normal;

        font-family: Rubik-light;
    }

}